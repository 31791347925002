import { useDisclosure } from '@chakra-ui/hooks'
import { Box } from '@chakra-ui/layout'
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Link,
} from '@chakra-ui/react'
import '@fontsource/dancing-script'
import '@fontsource/noto-sans-jp'
import { useLocation } from '@reach/router'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby-link'
import { Squeeze as Hamburger } from 'hamburger-react'
import 'modern-css-reset'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import 'swiper/css'
import logoSvg from '../images/logo.svg'
import adobeLoader from '../utils/adobeLoader'

export const IntroContext = createContext()

export default function Layout(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const [isIntro, setIsIntro] = useState(false)

  const location = useLocation()

  useEffect(() => {
    return () => {
      if (process.browser) adobeLoader(document)
    }
  }, [])

  useEffect(() => {
    let blur = document.getElementById('___gatsby')
    isOpen
      ? (blur.style.filter = 'blur(12px)')
      : (blur.style.filter = 'blur(0)')
  }, [isOpen])

  async function editPage(value) {
    onClose()
    if (location.pathname === '/') await setIsIntro(true)
    navigate(`/${value}`)
  }

  return (
    <>
      <Helmet>
        <title>ADOYOSU</title>
      </Helmet>
      {/* <IntroContext.Provider value={{ isIntro, setIsIntro }}>
        <IntroModal />
      </IntroContext.Provider> */}
      <motion.div
        style={{
          position: 'relative',
        }}
        initial={{ scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{
          type: 'spring',
          mass: 0.35,
          stiffness: 75,
          duration: 0.3,
        }}
      >
        <img
          src={logoSvg}
          style={{
            width: '180px',
            position: 'absolute',
            top: '20px',
            left: '20px',
            letterSpacing: '4px',
            cursor: `pointer`,
            zIndex: 1,
          }}
          alt="adoyosu"
          onClick={() => editPage('')}
        />
        <Box
          top={{ base: '10px', md: '80px' }}
          display={{ base: 'inline-flex', md: 'flex' }}
          justifyContent={{ base: 'flex-end', md: 'flex-start' }}
          width={{ base: '98%', md: 'auto' }}
          marginLeft={{ base: '0', md: '20px' }}
          position={location.pathname === '/' ? 'absolute' : 'sticky'}
          sx={{
            zIndex: 10,
          }}
        >
          <Hamburger
            colorScheme="teal"
            toggled={isOpen}
            toggle={onOpen}
            rounded
          />
        </Box>
        {props.children}

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Link
                style={{
                  fontSize: '24px',
                  fontFamily: `Helvetica`,
                  fontWeight: 600,
                  letterSpacing: '1px',
                  marginTop: '32px',
                  marginBottom: '32px',
                }}
                onClick={() => editPage('')}
              >
                Home
              </Link>
              <Link
                style={{
                  fontSize: '24px',
                  fontFamily: `Helvetica`,
                  fontWeight: 600,
                  letterSpacing: '1px',
                  marginBottom: '32px',
                }}
                onClick={() => editPage('business')}
              >
                Business
              </Link>
              <Link
                style={{
                  fontSize: '24px',
                  fontFamily: `Helvetica`,
                  fontWeight: 600,
                  letterSpacing: '1px',
                  marginBottom: '32px',
                }}
                onClick={() => editPage(`company`)}
              >
                Company
              </Link>
              <Link
                style={{
                  fontSize: '24px',
                  fontFamily: `Helvetica`,
                  fontWeight: 600,
                  letterSpacing: '1px',
                  marginBottom: '32px',
                }}
                onClick={() => editPage(`policy`)}
              >
                Policy
              </Link>
              <Link
                style={{
                  fontSize: '24px',
                  fontFamily: `Helvetica`,
                  fontWeight: 600,
                  letterSpacing: '1px',
                  marginBottom: '32px',
                }}
                onClick={() => editPage(`contact`)}
              >
                Contact
              </Link>
            </DrawerBody>
            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </motion.div>
    </>
  )
}
